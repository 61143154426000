import React from "react";
import MajorItem from "../components/MajorItem";
import khosher from "../images/logoKhoser.png";
import ItemData from "../components/MajorItemLiat.js";
import Girl from '../images/grl.png';
import Certification from "./Certification";
import "./Style.css";
import Footer from "./Footer";



import { useNavigate } from "react-router-dom";

import Crousel from "./Crousel";
import ClientSlider from "./ClientSlider";


export default function Home() {
  const navii = useNavigate();
  const mystyle = {
    color: "rgba(43,45,106,1)",
    fontweight: 900,
    fontFamily: "Oswald",
  };

  const mystyle2 = {
    fontFamily: "Roboto",
    fontSize: '22px'
  };

  const mystyle3 = {
    color: "white",
    fontweight: 900,
    fontFamily: "Roboto",
  };
 
  const mystyle5 = {
    color: "rgba(43,45,106,1)",
    // fontFamily: "Comfortaa",
    backgroundColor:"white",
    fontFamily: "Roboto",

  };

  return (
    <>
      {/* parent fiv */}
      <div className="container-fluid">

    <Crousel/>

        
        {/* Some About Section */}
        <br />
        <div className="container my-0" style={{backgroundColor:"white"}}>
          <div className="row">
            <div className="col-md-6">
              <div className="row">
                <div className="col-lg-12">
                  <h2 style={mystyle5}>
                    <strong
                      // style={{
                      //   "borderBottom": "solid",
                      //   "padding": "0.5rem"
                      // }}
                    className='khosherKiUnderLine'
                    id='headdd'
                    >
                      Kosher Industrial Tape
                    </strong>
                  </h2>
                </div>
              </div>
              
              <br />
              <p style={mystyle2}>
                Kosher private limited is a manufacturer and distributor of
                superior quality self adhesive tapes in Pakistan. Kosher private
                limited offers wide range of self adhesive tapes. Products that
                are designed to suit any array of specifications in the leather,
                electrical, electronics, printing and packaging industry
              </p>
              <button
                className="btn btn-primary form-control"
                id="cardBtn"
                onClick={() => {
                  navii("/about");
                }}
              >
                Read More
              </button>
            </div>

            <span className="col-md-2"></span>

            <div className="col-md-4 AboutImageDisplayNone ">
              <img src={khosher} className="my-4 " alt="" srcSet="" />
            </div>

            {/* Some About Section end */}
          </div>
          <br />
        </div>
        <br />

                
        {/* TOP ITEM */}

        <div className="container  " style={{ backgroundColor: "white" }}>
          <h2 className=" text-center my-4" style={mystyle5}>
            <strong
             style={{
                        "borderBottom": "solid",
                        "paddingBottom": "0.5rem",
                      }}
                      id='headdd'
                      >MAJOR PRODUCTS</strong>
            <br />
            <span
              style={{
                height: "0.325rem",
                backgroundColor: "#999999",
                display: "inline-block",
              }}
            ></span>
            {/* <span className="divider"></span> */}
          </h2>

          <div className="row">
            {ItemData.map((val) => {
              return (
                <MajorItem
                  Title={val.Title}
                  itemName={val.itemName}
                  image={val.imgsrc}
                  btnText={val.btnText}
                  key={val}
                />
              );
            })}
            ;
          </div>
        </div>
        <br/>
        {}



          <ClientSlider/>
        




        
{/* newletter */}
        <div className="row d-flex justify-content-center align-items-center rows  my-3 " id="parentDiv">
        <div className="col-md-6 parentNews">
          <div className="card crdSubs">
            <div className="text-center">
              {" "}
              <img
                src={Girl}
                width="200"
                alt="subcribeImg"
              />{" "}
              <span className="d-block mt-3" style={{fontFamily:'Roboto'}}>
                Subscribe to our newsletter in order not to miss new arrivals{" "}
                <br /> promotions and discounts of our store
              </span>
              <div className="mx-5">
                <div className="input-group mb-3 mt-4">
                  {" "}
                  <input
                    type="text"
                    className="form-control inptControl"
                    placeholder="Enter email"
                    aria-label="Recipient's username"
                    aria-describedby="button-addon2"
                  />{" "}
                  <button
                    className="btn btn-primary btn-sm subsBtn"
                    type="button"
                    id="button-addon2"
                    
                    
                  >
                    Subscribe
                  </button>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
        <Certification />
        <br/><br/><br/><br/><br/><br/><br/>

        <Footer /> 
      </div>
    </>
  );
}
