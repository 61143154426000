import React from 'react'
import { Link , Navigate, useNavigate} from 'react-router-dom'

export default function ProductItemShowOnLink(props) {
  return (
      <>
    <div className="col-lg-4 my-3 ">
        <div className="card text-center "  >
          <img
            src={props.image}
            
            className="card-img-top cardImage"
            alt="..."

          />
          <div
            className="card-body"
            style={{ backgroundColor: "rgb(53 ,19, 90)", color: "white" }}
          >
            <p className="card-title">{props.Title}</p>
            <p className="card-text">
              {/* Some quick example text to build on the card title and make up the
              bulk of the card's content. */}
              <strong>{props.itemName}</strong>
            </p>
            {/* <button
              id="cardBtn"
              className="btn btn-primary form-control"
              style={{backgroundColor:"rgba(43,45,106,1)"}}
              
            >
            
          Read More
            </button> */}
            
          </div>
        </div>
      </div>


   </>
    )
}
