import React from 'react'
import './productStyle.css'
import {ClothTapeeee} from '../AdhesiveTapeList'
// import MajorItem from '../../../../components/MajorItem'
import MajorProduct from '../ProductItemShowOnLink'


export default function ClothTape() {
  return (
   <>
   <div className="row">
            {ClothTapeeee.map((val) => {
              return (
                <MajorProduct
                  Title={val.Title}
                  itemName={val.itemName}
                  image={val.imgsrc}
                />
              );
            })}
            ;
          </div>

  

   
   </>
  )
}
