import ClothDuctTape from "../../../images/tableTapes/clothDuctTape.PNG";
import NaturalTapeTemp from "../../../images/tableTapes/naturalRubberClothTape.PNG";
import printClothTapeTemp from "../../../images/tableTapes/printClothDucttapee.PNG";
import colorFullClothTapeTemp from "../../../images/tableTapes/colourfullTape.PNG";
import BrownClothTapeTemp from "../../../images/tableTapes/brownClothTape.PNG";
import gafferTapeTemp from "../../../images/tableTapes/gafferTape.PNG";
import transparentTapeTemp from "../../../images/tableTapes/transparentclothtape.PNG";


//--------------------Masking Tape ---------------------------------

import generalPurposeTapeTemp from "../../../images/tableTapes/generalPurposeMasking.PNG";
import AutomativeTapeTemp from "../../../images/tableTapes/generalPurposeMasking.PNG";
import BrownMaskingTapeTemp from "../../../images/tableTapes/BrownMaskingTape.PNG";
import masking80C from "../../../images/tableTapes/masking80C.PNG";
import bluePainter from "../../../images/tableTapes/bluePainter.PNG";
import colorFullMaskingTape from "../../../images/tableTapes/colorFullMaskingTape.PNG";
import hightemp120 from "../../../images/tableTapes/hightemp120.PNG";

//--------------------BOPP TAPE---------------------------------------------
import acryLic from "../../../images/tableTapes/acryLic.PNG";
import Bopptapehotmert from "../../../images/tableTapes/Bopptapehotmert.PNG";
import naturalRubberBopp from "../../../images/tableTapes/naturalRubberBopp.PNG";
import solventBopp from "../../../images/tableTapes/solventBopp.PNG";
import clearnoice from "../../../images/tableTapes/clearnoice.PNG";
import metalizedboop from "../../../images/tableTapes/metalizedboop.PNG";
import masterRoll from "../../../images/tableTapes/masterRoll.PNG";
import printedBOPP from "../../../images/tableTapes/printedBOPP.PNG";
import yellowishbopp from "../../../images/tableTapes/yellowishbopp.PNG";

//---------------------PVC TAPE---------------
import pvcPipe from "../../../images/tableTapes/pvcPipe.PNG";
import pvcInsulation from "../../../images/tableTapes/pvcInsulation.PNG";
import PvcElectr from "../../../images/tableTapes/PvcElectr.PNG";
import pvcWarning from "../../../images/tableTapes/pvcWarning.PNG";
import pvcProtention from "../../../images/tableTapes/pvcProtention.PNG";
import pvcTransparent from "../../../images/tableTapes/pvcTransparent.PNG";
import selffusing from "../../../images/tableTapes/selffusing.PNG";
import clothWireHarness from "../../../images/tableTapes/clothWireHarness.PNG";
import easyTear from "../../../images/tableTapes/easyTear.PNG";

//-------------------------DOUBLE TISSUE TAPE-----------------

import doubleSidePet from "../../../images/tableTapes/doubleSidePet.PNG";
import doubleSidedAcrylic from "../../../images/tableTapes/doubleSidedAcrylic.PNG";
import doubleSidedFoam from "../../../images/tableTapes/doubleSidedFoam.PNG";
import doubleSidedFiber from "../../../images/tableTapes/doubleSidedFiber.PNG";
import doubleSidedTissue from "../../../images/tableTapes/doubleSidedTissue.PNG";



export const ClothTapeeee = [
  {
    itemName: "Cloth / Duct Tape ",
    imgsrc:
      "https://www.ygtape.com/upload/admin/20200622/18fda64bf41eccac241b6e6a92633024.png",
    Title: "Kosher Industrial Packing Tape",
    tempImg: ClothDuctTape,
  },
  {
    itemName: "Natural Rubber ",
    imgsrc:
      "https://www.ygtape.com/upload/admin/20200519/bdf7560712a6b40a1b6767081ead77ba.png",
    Title: "Kosher Industrial Packing Tape",
    tempImg: NaturalTapeTemp,
  },
  {
    itemName: "Print Cloth/Duct Tape",
    imgsrc:
      "https://www.ygtape.com/upload/admin/20200519/20abaac242b84bfb8e9a975c39be5499.png",
    Title: "Kosher Industrial Packing Tape",
    btnText: "View Catalog",
    tempImg: printClothTapeTemp,
  },
  {
    itemName: "ColorFull Cloth Tape",
    imgsrc:
      "https://www.ygtape.com/upload/admin/20200523/ea283bc42637e2e2560397993b2ff893.png",
    Title: "Kosher Industrial Packing Tape",
    btnText: "View Catalog",
    tempImg: colorFullClothTapeTemp,
  },
  {
    itemName: "Brown Cloth TAPE",
    imgsrc:
      "https://www.ygtape.com/upload/admin/20200819/ae83497a69b90ba22431c8c7114bc938.png",
    Title: "Kosher Industrial Packing Tape",
    btnText: "View Catalog",
    tempImg: BrownClothTapeTemp,
  },
  {
    itemName: "Gaffer Tape",
    imgsrc:
      "https://www.ygtape.com/upload/admin/20200525/846ce73bc3c91c94443e4b9f09b761f8.png",
    Title: "Kosher Industrial Packing Tape",
    btnText: "View Catalog",
    tempImg: gafferTapeTemp,
  },
  {
    itemName: "Transaprent Cloth Tape",
    imgsrc:
      "https://www.ygtape.com/upload/admin/20200819/c450ab64242458ff259753776dc8d4f1.png",
    Title: "Kosher Industrial Packing Tape",
    btnText: "View Catalog",
    tempImg: transparentTapeTemp,
  },
];

export const MaskingTapeeee = [
  {
    itemName: "General Purpose Masking Tape",
    imgsrc:
      "https://www.ygtape.com/upload/admin/20200519/ccb6d1e26be600706b99d8dcc4540cfe.png",
    Title: "Kosher Industrial Packing Tape",
    btnText: "View Catalog",
    tempImg: generalPurposeTapeTemp,
  },
  {
    itemName: "Automotive Masking Tape",
    imgsrc:
      "https://www.ygtape.com/upload/admin/20200519/5467f1606f45881586590621c11fba2a.png",
    Title: "Kosher Industrial Packing Tape",
    btnText: "View Catalog",
    tempImg: AutomativeTapeTemp,
  },
  {
    itemName: "Blue Painter Tape",
    imgsrc:
      "https://www.ygtape.com/upload/admin/20200519/739c0cc9e81a9fef6632705cae249b0c.png",
    Title: "Kosher Industrial Packing Tape",
    btnText: "View Catalog",
    tempImg: bluePainter,
  },
  {
    itemName: "Colorful Masking Tape",
    imgsrc:
      "https://www.ygtape.com/upload/admin/20200519/0a84fccf9a6a202a86575bb39bcc35c9.png",
    Title: "Kosher Industrial Packing Tape",
    btnText: "View Catalog",
    tempImg: colorFullMaskingTape,
  },
  {
    itemName: "high temp (100C-120C)  Tape",
    imgsrc:
      "https://www.ygtape.com/upload/admin/20200519/ec2ee6980162209456b097ecb43268f2.png",
    Title: "Kosher Industrial Packing Tape",
    btnText: "View Catalog",
    tempImg: hightemp120,
  },
  {
    itemName: "Temp Resist (80C)  Tape",
    imgsrc:
      "https://www.ygtape.com/upload/admin/20200519/dc222d7008b9fd82f5d37de83ebdf913.png",
    Title: "Kosher Industrial Packing Tape",
    btnText: "View Catalog",
    tempImg: masking80C,
  },
  {
    itemName: "Brown Masking Tape",
    imgsrc:
      "https://www.ygtape.com/upload/admin/20200819/a1687f44d6e6f78ae53fc2cd8f667281.png",
    Title: "Kosher Industrial Packing Tape",
    btnText: "View Catalog",
    tempImg: BrownMaskingTapeTemp,
  },
];

export const BOPPTapeeee = [
  {
    itemName: "Acrylic BOPP Tape",
    imgsrc:
      "https://www.ygtape.com/upload/admin/20200523/527065c557eacfad948d1616c1907305.png",
    Title: "Kosher Industrial Packing Tape",
    btnText: "View Catalog",
    tempImg: acryLic,
  },
  {
    itemName: "Hot Melt BOPP Tape",
    imgsrc:
      "https://www.ygtape.com/upload/admin/20200523/9e202d67da884962166f63826bd91622.png",
    Title: "Kosher Industrial Packing Tape",
    btnText: "View Catalog",
    tempImg: Bopptapehotmert,
  },
  {
    itemName: "Natural Rubber Tape",
    imgsrc:
      "https://www.ygtape.com/upload/admin/20200523/c4d682591a34a36c6bd5a12a8be3be30.png",
    Title: "Kosher Industrial Packing Tape",
    btnText: "View Catalog",
    tempImg: naturalRubberBopp,
  },
  {
    itemName: "Solvent BOPP Tape",
    imgsrc:
      "https://www.ygtape.com/upload/admin/20200622/0867a8d6cda430501006ce04f8b909bf.png",
    Title: "Kosher Industrial Packing Tape",
    btnText: "View Catalog",
    tempImg: solventBopp,
  },
  {
    itemName: "Low Noise BOPP Tape",
    imgsrc:
      "https://www.ygtape.com/upload/admin/20200523/b09adf61bb3e58239be1d8929ff6b877.png",
    Title: "Kosher Industrial Packing Tape",
    btnText: "View Catalog",
    tempImg: clearnoice,
  },
  {
    itemName: "Metalized BOPP Tape",
    imgsrc:
      "https://www.ygtape.com/upload/admin/20200523/c27686b0897e0f32c22907dc5c73208b.png",
    Title: "Kosher Industrial Packing Tape",
    btnText: "View Catalog",
    tempImg: metalizedboop,
  },
  {
    itemName: "Master Roll BOPP Tape",
    imgsrc:
      "https://www.ygtape.com/upload/admin/20200523/0df48b0dc72853bdc4e7e31f52070af1.png",
    Title: "Kosher Industrial Packing Tape",
    btnText: "View Catalog",
    tempImg: masterRoll,
  },
  {
    itemName: "Printed BOPP Tape",
    imgsrc:
      "https://www.ygtape.com/upload/admin/20200523/58f055d07ff625340ac8560bb7c54c86.png",
    Title: "Kosher Industrial Packing Tape",
    btnText: "View Catalog",
    tempImg: printedBOPP,
  },
  {
    itemName: "Yellowish BOPP Tape",
    imgsrc:
      "https://www.ygtape.com/upload/admin/20200523/361af95f1d1449e5b216db1eeaeb71d8.png",
    Title: "Kosher Industrial Packing Tape",
    btnText: "View Catalog",
    tempImg: yellowishbopp,
  },
];

export const PVCTapeeee = [
  {
    itemName: "PVC Insulation Tape",
    imgsrc:
      "https://www.ygtape.com/upload/admin/20200523/b3a2e66f56bd3bcabf4332e9256062b5.png",
    Title: "Kosher Industrial Packing Tape",
    btnText: "View Catalog",
    tempImg: pvcInsulation,
  },
  {
    itemName: "PVC Electrical Tape",
    imgsrc:
      "https://www.ygtape.com/upload/admin/20200628/c1cb15c2b3216aee5a11615ef82b9d84.png",
    Title: "Kosher Industrial Packing Tape",
    btnText: "View Catalog",
    tempImg: PvcElectr,
  },
  {
    itemName: "PVC Warning Tape",
    imgsrc:
      "https://www.ygtape.com/upload/admin/20200608/4778755e87f0fc7b474ff210e5940181.png",
    Title: "Kosher Industrial Packing Tape",
    btnText: "View Catalog",
    tempImg: pvcWarning,
  },
  {
    itemName: "PVC Protection Tape",
    imgsrc:
      "https://www.ygtape.com/upload/admin/20200523/74e07fe561ede04fe11b4502d5491cf7.png",
    Title: "Kosher Industrial Packing Tape",
    btnText: "View Catalog",
    tempImg: pvcProtention,
  },
  {
    itemName: "Transparent PVC Tape",
    imgsrc:
      "https://www.ygtape.com/upload/admin/20200523/79a344d6b1468c7fd31fa51cb0d0e736.png",
    Title: "Kosher Industrial Packing Tape",
    btnText: "View Catalog",
    tempImg: pvcTransparent,
  },
  {
    itemName: "Self Fusing Rubber Tape",
    imgsrc:
      "https://www.ygtape.com/upload/admin/20200523/daf7cacab01a374803fff321bec2753f.png",
    Title: "Kosher Industrial Packing Tape",
    btnText: "View Catalog",
    tempImg: selffusing,
  },
  {
    itemName: "Cloth Wire Harness Tape",
    imgsrc:
      "https://www.ygtape.com/upload/admin/20200523/e5b3d1024edfe108f10441782d620038.png",
    Title: "Kosher Industrial Packing Tape",
    btnText: "View Catalog",
    tempImg: clothWireHarness,
  },
  {
    itemName: "Easy Tear PVC Tape",
    imgsrc:
      "https://www.ygtape.com/upload/admin/20200523/a883034091912bbe65aeee76bb7bf945.png",
    Title: "Kosher Industrial Packing Tape",
    btnText: "View Catalog",
    tempImg: easyTear,
  },
  {
    itemName: "PVC Pipe Tape",
    imgsrc:
      "https://www.ygtape.com/upload/admin/20200628/411267009a82bdfafce3169d814699bd.png",
    Title: "Kosher Industrial Packing Tape",
    btnText: "View Catalog",
    tempImg: pvcPipe,
  },
];

export const DoubleSided = [
  {
    itemName: "Double Sided Tissue Tape",
    imgsrc:
      "https://www.ygtape.com/upload/admin/20200523/f74fd0a270546549803cf4c6e24dd1fe.png",
    Title: "Kosher Industrial Packing Tape",
    btnText: "View Catalog",
    tempImg: doubleSidedTissue,
  },
  {
    itemName: "Double Sided Fiberglass Tape",
    imgsrc:
      "https://www.ygtape.com/upload/admin/20200628/2b4a66a4c0d16ee5ac20ad670a29a15f.png",
    Title: "Kosher Industrial Packing Tape",
    btnText: "View Catalog",
    tempImg: doubleSidedFiber,
  },
  {
    itemName: "Double Sided Foam Tape",
    imgsrc:
      "https://www.ygtape.com/upload/admin/20200523/87a59fe2d3ba80bd813f0af59134aeca.png",
    Title: "Kosher Industrial Packing Tape",
    btnText: "View Catalog",
    tempImg: doubleSidedFoam,
  },
  {
    itemName: "Double Sided Acrylic Tape",
    imgsrc:
      "https://www.ygtape.com/upload/admin/20200523/8c8fc8a07810506e8828fd2e94feef3c.png",
    Title: "Kosher Industrial Packing Tape",
    btnText: "View Catalog",
    tempImg: doubleSidedAcrylic,
  },
  {
    itemName: "Double Sided Pet Tape",
    imgsrc:
      "https://www.ygtape.com/upload/admin/20200819/1941358778b8b43a64fab498b2287867.png",
    Title: "Kosher Industrial Packing Tape",
    btnText: "View Catalog",
    tempImg: doubleSidePet,
  },
];

//   export default {ClothTapeeee,MaskingTapeeee,BOPPTapeeee,PVCTapeeee,DoubleSided};
