import React from 'react'
import { DoubleSided } from "../HotMeltAdhesiveTapeList";
// import MajorItem from '../../../../components/MajorItem'
import MajorProduct from "../HotMeltProductItemSHOW";


export default function DoubleSidedd() {
  return (
    <>
    <div className="row">
      {DoubleSided.map((val, i) => {
        return (
          <MajorProduct
            Title={val.Title}
            itemName={val.itemName}
            image={val.imgsrc}
            tempImgg={val.tempImg}
            key={i}
          />
        );
      })}
      ;
    </div>
  </>
  )
}
