const ItemsData = [
  {
    itemName: "BOPP ADHESIVE PACKING TAPE ",
    imgsrc:
      "https://www.toplinkpackaging.com/images/Products/bopp_packing_tape.jpg",
    Title: "Kosher Industrial Packing Tape",
    btnText: "View Catalog",
  },
  {
    itemName: "SELF ADHESIVE MASKING TAPE   ",
    imgsrc: "https://www.toplinkpackaging.com/images/Products/masking_tape.jpg",
    Title: "Kosher Industrial Packing Tape",
    btnText: "View Catalog",
  },
  {
    itemName: "ADHESIVE HOTMELT RUBBER TAPE ",
    imgsrc:
      "https://www.ygtape.com/upload/admin/20200519/bdf7560712a6b40a1b6767081ead77ba.png",
    Title: "Kosher Industrial Packing Tape",
    btnText: "View Catalog",
  },
  {
    itemName: "ADHESIVE WATER BASE TAPE",
    imgsrc:
      "https://www.ygtape.com/upload/admin/20200525/478b9a0ab35a1398c6145d68eee7101f.png",
    Title: "Kosher Industrial Packing Tape",
    btnText: "View Catalog",
  },
  {
    itemName: "HOT MELT PACKING TAPE",
    imgsrc:
      "https://www.ygtape.com/upload/admin/20200519/dc222d7008b9fd82f5d37de83ebdf913.png",
    Title: "Kosher Industrial Packing Tape",
    btnText: "View Catalog",
  },
  {
    itemName: "DOUBLE SIDED TISSUE TAPE",
    imgsrc:
      "https://www.ygtape.com/upload/admin/20200523/7a57700a0085307ada0deb409bd5ee3f.png",
    Title: "Kosher Industrial Packing Tape",
    btnText: "View Catalog",
  },
];

export default ItemsData;
