import React from 'react'
import {ClothTapeeee} from '../../HotMeltAdhesive/HotMeltAdhesiveTapeList'
// import MajorItem from '../../../../components/MajorItem'
import MajorProduct from '../../HotMeltAdhesive/HotMeltProductItemSHOW'


export default function ClothTape() {
  return (
    <>
    <div className="row">
             {ClothTapeeee.map((val,i) => {
               return (
                 <MajorProduct
                   Title={val.Title}
                   itemName={val.itemName}
                   image={val.imgsrc}
                  tempImgg= {val.tempImg}
                  key={i}
                 />
               );
             })}
             ;
           </div>
 
   
 
    
    </>
  )
}
