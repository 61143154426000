import React from 'react'
import khosher from "../images/logoKhoser.png";
import Masking from "../images/JawwadmaskingTape.jpeg";
import Streach from "../images/JawwadStrectchFil.jpeg";
import Browm from "../images/JawwadBrowm.jpeg";
import HotMelt from "../images/jawadhotmelt.jpeg";
import ourmission from "../images/hamzi.jpg";
import alter from "../images/KPL.jpg";
import Footer from './Footer';
import './AboutStyle.css'
import PPdf from '../images/pamphet.pdf'
import Certification from '../Pages/Certification'

export default function About() {

  const downloadPDF=()=>{
    console.log("i clicked");
    // <a href={PPdf} download>
    // </a>
  }
  return (
    <div className='container-fluid'>
    <div className="crousell">
    <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-inner">
    <div className="carousel-item">
      <img src={ourmission} style={{ width: "100%", height: "auto" }} alt="..."/>
    </div>
   
    <div className="carousel-item active">
      <img src={alter} style={{ width: "100%", height: "auto" }}  alt="..."/>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div></div>
    <div className="aboutsection">
    <div className="row">
    <div className="col-md-1"></div>
      <div className="col-md-5 aboutDiv">
        <h1 id='headdd' style={{color:'#2b2d6a'}}><strong>Kosher Private Limited</strong></h1>
        <p style={{fontSize: '18px'}}><strong>Kosher private limited is a manufacturer and distributor of superior quality self adhesive tapes in Pakistan. Kosher private limited offers wide range of self adhesive tapes. Products that are designed to suit any array of specifications in the leather, electrical,
         electronics, printing and packaging industry</strong></p>
         <a href={PPdf} download="Khosher Private Limited">
    <button className="btn dwnloadBtn form-control"><i className="fa fa-download"></i> Download</button>
    </a>
      </div>
      <div className="col-md-1"></div>
      <div className="col-md-5">
        <img src={khosher} height='250px' alt="logo" id='imageKosher' />
      </div>
    </div>
    </div>
    <br/><br/>
    <div className="topproduct">
    <h1 className="fw-light text-center text-lg-start mt-4 mb-0" id='headdd' style={{color:'#2b2d6a'}}><strong>Top Selling Products</strong></h1>

  <hr className="mt-2 mb-5" style={{color:'#2b2d6a'}}/>

  <div className="row text-center text-lg-start">

    <div className="col-lg-3 col-md-4 col-6">
      <a href="#" className="d-block mb-4 h-100">
        <img className="img-fluid img-thumbnail" src={Masking} alt=""/>
      </a>
    </div>
    <div className="col-lg-3 col-md-4 col-6">
      <a href="#" className="d-block mb-4 h-100">
        <img className="img-fluid img-thumbnail" src={Streach} alt=""/>
      </a>
    </div>
    <div className="col-lg-3 col-md-4 col-6">
      <a href="#" className="d-block mb-4 h-100">
        <img className="img-fluid img-thumbnail" src={Browm} alt=""/>
      </a>
    </div>
    <div className="col-lg-3 col-md-4 col-6">
      <a href="#" className="d-block mb-4 h-100">
        <img className="img-fluid img-thumbnail" src={HotMelt} alt=""/>
      </a>
    </div>
    
  </div>
  <Certification/>
    </div><br /><br /><br />
    <Footer  />
    </div>
    
  )
}
