import React from 'react'
import {DoubleSided} from '../AdhesiveTapeList'
import MajorProduct from '../ProductItemShowOnLink'

export default function DoubleSidedTape() {
  return (
    <>
      <div className="row">
            {DoubleSided.map((val) => {
              return (
                <MajorProduct
                  Title={val.Title}
                  itemName={val.itemName}
                  image={val.imgsrc}
                />
              );
            })}
            ;
          </div>
    </>
  )
}
