import './App.css';
import Nav from './Pages/Nav';
import About from './Pages/About';
import ContactUs from './Pages/ContactUs';
import QualityControl from './Pages/QualityControl';
import Home from './Pages/Home';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';


import HotMeltAdhesive from './Pages/Prouducts/HotMeltAdhesive/HotMeltAdhesive';
import AdhesiveProduct from './Pages/Prouducts/AdhesiveTape/AdhesiveTape'

import ClothTape from './Pages/Prouducts/AdhesiveTape/Products/ClothTape'
import MaskingTape from './Pages/Prouducts/AdhesiveTape/Products/MaskingTape'
import BoppTape from './Pages/Prouducts/AdhesiveTape/Products/BoppTape'
import PVCTape from './Pages/Prouducts/AdhesiveTape/Products/PVcTape'
import DoubleSided from './Pages/Prouducts/AdhesiveTape/Products/DoubleSidedTape'

import HotMeltClothTape from './Pages/Prouducts/HotMeltAdhesive/Product/ClothTape'
import HotMeltMaskingTape from './Pages/Prouducts/HotMeltAdhesive/Product/MaskingTape'
import HotMeltBoppTape from './Pages/Prouducts/HotMeltAdhesive/Product/BOPPTape'
import HotMeltPVCTape from './Pages/Prouducts/HotMeltAdhesive/Product/PVCTape'
import HotMeltDoubleSided from './Pages/Prouducts/HotMeltAdhesive/Product/DoubleSided'


import Product from './Pages/Product';



function App() {
  return (
  <>
  <Router>
  <Nav/>
  
  <Routes>
      
      
      <Route path='/' element={<Home/>}/>
      
      <Route path='/product' element={<Product/>}/>
      
      <Route path='/adhesivetape' element={<AdhesiveProduct/>}>
      {/* <Route path='bopptape' element={<Bopptape/>}/> */}
      {/* <Route path='tissueTape' element={<TissueTape/>}/> */}
      <Route path='clothtape' element={<ClothTape/>}/>
      <Route path='maskingtape' element={<MaskingTape/>}/>
      <Route path='bopptape' element={<BoppTape/>}/>
      <Route path='pvctape' element={<PVCTape/>}/>
      <Route path='doublesidedtape' element={<DoubleSided/>}/>


      </Route>
      
      <Route path='/hotmeltAdhesive' element={<HotMeltAdhesive/>}>

      <Route path='hotmeltclothtape' element={<HotMeltClothTape/>}/>
      <Route path='hotmeltmaskingtape' element={<HotMeltMaskingTape/>}/>
      <Route path='hotmeltbopptape' element={<HotMeltBoppTape/>}/>
      <Route path='hotmeltpvctape' element={<HotMeltPVCTape/>}/>
      <Route path='hotmeltdoublesidedtape' element={<HotMeltDoubleSided/>}/>

      </Route>
      
      
    
    
    <Route exact path='/qualitycontrol' element={<QualityControl/>}/> 
    <Route exact path='/about' element={<About/>}/> 
    <Route exact path='/contactus' element={<ContactUs/>}/>
  </Routes>
    
  </Router>
  </>
  );
}

export default App;
