import React from 'react'
import {PVCTapeeee} from '../AdhesiveTapeList'
import MajorProduct from '../ProductItemShowOnLink'



export default function PVcTape() {
  return (
    <>
      <div className="row">
            {PVCTapeeee.map((val) => {
              return (
                <MajorProduct
                  Title={val.Title}
                  itemName={val.itemName}
                  image={val.imgsrc}
                />
              );
            })}
            ;
          </div>
    </>
  )
}
