import React from 'react'
import kosher from '../images/logoKhoser.png'
import '../components/MotivationalQuote.css'

export default function MotivationalQuote() {
  return (
  <>
    <div className="container motivaitonalArea my-3">
      <div className="row my-3">
      <div className="col-md-1"></div>
    <div className="col-md-7 quote">
      <p className='contennnt'><i class="fa fa-quote-left" aria-hidden="true"></i> Be a yardstick of quality. Some people aren't used to an environment 
      where excellence is expected-The definition of insanity is doing the same thing over and over again and expecting different 
      outcomes <i class="fa fa-quote-right" aria-hidden="true"></i><b r /><i> --Director</i></p>
      
      </div>
      <div className="col-md-1 quote"></div>
      <div className="col-md-2 quote">
        <img src={kosher} id="quoteImageee" className="float-right" alt="" srcset=" " />
      </div>
    </div>
</div>
  </>
  )
}
