import React from "react";
import { MaskingTapeeee } from "../../HotMeltAdhesive/HotMeltAdhesiveTapeList";
// import MajorItem from '../../../../components/MajorItem'
import MajorProduct from "../../HotMeltAdhesive/HotMeltProductItemSHOW";

export default function MaskingTape() {
  return (
    <>
      <div className="row">
        {MaskingTapeeee.map((val, i) => {
          return (
            <MajorProduct
              Title={val.Title}
              itemName={val.itemName}
              image={val.imgsrc}
              tempImgg={val.tempImg}
              key={i}
            />
          );
        })}
        ;
      </div>
    </>
  );
}
