import React from 'react'
import { BOPPTapeeee } from "../../HotMeltAdhesive/HotMeltAdhesiveTapeList";
// import MajorItem from '../../../../components/MajorItem'
import MajorProduct from "../../HotMeltAdhesive/HotMeltProductItemSHOW";




export default function BOPPTape() {
  return (
    <>
    <div className="row">
      {BOPPTapeeee.map((val, i) => {
        return (
          <MajorProduct
            Title={val.Title}
            itemName={val.itemName}
            image={val.imgsrc}
            tempImgg={val.tempImg}
            key={i}
          />
        );
      })}
      ;
    </div>
  </>
  )
}
