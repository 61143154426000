import React from 'react'
import './Nav.css'
import Logo from '../images/logoKhoser.png'
import {Link,useNavigate} from 'react-router-dom'

export default function Nav() {
  const navi = useNavigate();

  return (
      <>

    {/* Navigation Menu  */}
    <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
  <div className="container-fluid">
  <Link className="navbar-brand " to="/">
      <img src={Logo} alt="Logo" width="20" height="25" className="d-inline-block align-text-top"/>
      <strong> Kosher Private Limited </strong>
    </Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarNav">
    <ul className="navbar-nav ms-auto">
        <li className="nav-item">
          <Link className="nav-link active lnks" aria-current="page" to="/">Home</Link>
        </li>
     
{/* <li className="nav-item dropdown lnks">
        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Products
        </a>
        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
          <Link className="dropdown-item" to="/adhesiveTape">Adhesive Tape</Link>
          <a className="dropdown-item" href="#">Hot Melt Adhesive</a>
         
        </div>
      </li> */}


      <li className="nav-item dropdown lnks">
          <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Products
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <li><Link className="dropdown-item" to="/adhesivetape">AdhesiveTape</Link></li>
            <li><Link className="dropdown-item" to="/hotmeltAdhesive">Hot Melt Adhesive</Link></li>
            
          </ul>
        </li>


{/* 
<li className="nav-item dropdown lnks">
        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          About
        </a>
        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
          <Link className="dropdown-item" to="/qualitycontrol">Quality Control</Link>
          <a className="dropdown-item" href="#">Another action</a>
         
          <a className="dropdown-item" href="#">Something else here</a>
        </div>
      </li> */}
      <li className="nav-item dropdown lnks">
          <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            About
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <li><Link className="dropdown-item" to="/qualitycontrol">Quality Control</Link></li>
            <li><Link className="dropdown-item" to="/about">About Kosher</Link></li>
            
          </ul>
        </li>


{/* 
        <li className="nav-item">
          <Link className="nav-link lnks" to="/about">About</Link>
        </li> */}
        <li className="nav-item">
          <Link className="nav-link lnks" to="/contactus">Contact Us</Link>
        </li>

      </ul>



        <div className="d-flex">
                <ul id='social'>
                    <li><a href='https://www.facebook.com/KOSHERPVTLTD' target="blank"><span className="social-icon social-facebook"><i className="fa fa-facebook"></i></span></a></li>
                    <li><a  href=' https://wa.me/923212434398' target="_blank"> <span className="social-icon social-google"><i className="fa fa-whatsapp"></i></span></a></li>
                    <li><span className="social-icon social-instagram"><i className="fa fa-instagram"></i></span></li>
                    <li><span className="social-icon social-linkedin"><i className="fa fa-linkedin"></i></span></li>
                    
                    
                </ul>
            </div>
    </div>
  </div>
</nav>




      </>
    
  )
}
