import React from 'react'
import './ClientSlider.css'



export default function ClientSlider() {
  return (
    <>
    
    <div className="container-fluid">
      <h2 className='text-center'><strong>Our Client</strong></h2>
        <div className="row">
  <div className="container sliderrrrr my-2">
    <section className="customer-logos slider" data-arrows="true">
      <div className="slide "><img alt='nation food' src="https://media-exp1.licdn.com/dms/image/C4D0BAQE3eGojmBPyWg/company-logo_200_200/0/1597818088563?e=2159024400&v=beta&t=f6Z0lB3VyWcIvwd6Eo0RVfgOs25_d6PRinRHUBgYhcQ"/></div>
      <div className="slide "><img alt='Gul Ahmed' src="https://i.pinimg.com/originals/c3/3c/e2/c33ce28cc76cd333dc3209c9e109fccc.png"/></div>
      <div className="slide "><img alt='nation food' src="https://www.yunustextile.com/wp-content/themes/yunustextile/images/og-logo.jpg   "/></div>
      <div className="slide "><img  alt='nation food' src="https://static.wixstatic.com/media/05d0da_10af2a55412743c9b88dfe9230998554~mv2.png/v1/fill/w_200,h_200,al_c,q_85,usm_0.66_1.00_0.01/abbott%20logo.webp"/></div>
      <div className="slide "><img alt='nation food' src="https://expandedramblings.com/wp-content/uploads/2018/09/Archer-Daniels-Midland-Statistics-and-Facts.png"/></div>
      <div className="slide "><img alt='nation food' src="https://media-exp1.licdn.com/dms/image/C4D0BAQGbWSa_3I6w5A/company-logo_200_200/0/1557906913938?e=2159024400&v=beta&t=aFH7HuzXZXrl2XCYhbYSepgEX9Hta-IOyIai4-kcf6g"/></div>
      
      <div className="slide "><img alt='nation food' src="https://zenprospect-production.s3.amazonaws.com/uploads/pictures/605c1c6061bd410001ca3f6a/picture"/></div>
     
    </section>
  </div>
</div>
</div>
  

    </>
  )
}
