import React from "react";
import "./HotMeltAdhesiveStyle.css";
import { NavLink, Outlet } from "react-router-dom";
import Footer from "../../Footer";
import Motivation from "../../../components/MotivationalQuote";

export default function HotMeltAdhesive() {
  return (
    <>
      <br />
      <br />
      <br />

      <h2 className="text-center">
        <strong>HotMelt Adhesive Tape</strong>
      </h2>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3">
            <ul className="list-group ">
              <li className="list-group-item ProductList">
                <NavLink to="/hotmeltAdhesive/hotmeltclothtape" className="txt">
                  {" "}
                  Cloth/Duct Tape{" "}
                </NavLink>
              </li>
              <li className="list-group-item ProductList">
                <NavLink
                  to="/hotmeltAdhesive/hotmeltmaskingtape"
                  className="txt"
                >
                  {" "}
                  Masking Tape{" "}
                </NavLink>
              </li>
              <li className="list-group-item ProductList">
                <NavLink to="/hotmeltAdhesive/hotmeltbopptape" className="txt">
                  {" "}
                  BOPP Tape{" "}
                </NavLink>
              </li>
              <li className="list-group-item ProductList">
                <NavLink to="/hotmeltAdhesive/hotmeltpvctape" className="txt">
                  {" "}
                  PVC Tape{" "}
                </NavLink>
              </li>
              <li className="list-group-item ProductList">
                <NavLink
                  to="/hotmeltAdhesive/hotmeltdoublesidedtape"
                  className="txt"
                >
                  {" "}
                  Double Sided Tape{" "}
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="col-md-8">
            <Outlet />
          </div>
        </div>
        <br />
        <br />
        <br />
        <Motivation />
        <br />
        <br />
        <Footer />
      </div>
    </>
  );
}
