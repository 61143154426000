import React from 'react'
import Certification1 from '../images/certification1.jpg'
import Certification2 from '../images/certification2.jpg'
import Certification3 from '../images/certification3.jpg'
import Certification4 from '../images/certification4.jpg'
import Certificate from '../sliderImages/ISOCERTIFICATE.pdf'

export default function Certification() {
  return (
    <>
      <div className="container my-4" style={{backgroundColor:"white"}}>
        <div className="row">
            <h2 className='text-center my-2'> Our Certification</h2><br />
            <p className="text-center my-2">We continous efforts, Excellence and innovation
            ,we have earned many international accredited Certification <b>ISO9001</b>
            ,<b>ISO14001</b>,<b>PK-QMS-011976</b><br/> and are an active member of <b>SGlA</b>.We have maintained
            <b> OEKO-TEX Standard 100</b>class 1 and are the only approved<br/> company to have held
            this honor in Pakistan
            </p>
            <div className="row my-3">
            <div className="col-md-2"></div>
                <div className="col-md-2">
                    <img src={Certification1} alt="" />
                </div>
                <div className="col-md-2">
                    <img src={Certification2} alt="" />
                </div>
                <div className="col-md-2">
                    <img src={Certification3} alt="" />
                </div>
                <div className="col-md-2">
                    <img src={Certification4} alt="" />
                </div>
            </div>
            <a href={Certificate} download>
            <button className='btn btn-primary form-control' style={{backgroundColor:'#2b2d6a' , borderRadius:'40px '}}>Download Certificate <strong>PK-QMS-011976</strong> </button>
            </a>
        </div>
      </div>
    </>
  )
}
