import React from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";

export default function HotMeltProductItemSHOW(props) {
  let downlaodTempImg ;
  function handleClick(itemName, img) {
    //this.setState({ justClicked: letter });
    //$("#staticBackdrop").modal("show");
    //$("#staticBackdropLabel").text(letter);
    var ele = document.getElementById("staticBackdrop");
    ele.classList.add("show");
    ele.style.display = "block";
    document.getElementById("staticBackdropLabel").innerText = itemName;
    document.getElementById("staticModalImage").src = img;
    downlaodTempImg = img;
  }

  function closeModal() {
    var ele = document.getElementById("staticBackdrop");
    ele.classList.remove("show");
    ele.style.display = "none";
  }

  return (
    <>
      <div className="col-lg-4 my-3 ">
        <div className="card text-center ">
          <img src={props.image} className="card-img-top cardImage" alt="..." />
          <div
            className="card-body"
            style={{ backgroundColor: "rgb(53 ,19, 90)", color: "white" }}
          >
            <p className="card-title">{props.Title}</p>
            <p className="card-text">
              {/* Some quick example text to build on the card title and make up the
              bulk of the card's content. */}
              <strong>{props.itemName}</strong>
            </p>

            <button
              type="button"
              className="btn btn-primary"
              style={{ backgroundColor: "#3b1564" }}
              onClick={() => handleClick(props.itemName, props.tempImgg)}
            >
              Temperature Chart
            </button>

            <div
              className="modal fade"
              id="staticBackdrop"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabindex="-1"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-scrollable">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5
                      className="modal-title"
                      id="staticBackdropLabel"
                      style={{ color: "black" }}
                    >
                      {console.log(
                        "=================>" + JSON.stringify(props)
                      )}
                      {/* {props.itemName} */}
                    </h5>

                    <button
                      onClick={() => closeModal()}
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-md-12">
                        <img
                          id="staticModalImage"
                          src={props.tempImgg}
                          className="img-fluid"
                          alt=""
                          srcset=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
                   {/* <a href={downlaodTempImg} download> <button type="button" className="btn btn-primary">
                      Download
                    </button></a> */}
                    <i style={{color:"purple"}}>KOSHER PVT LTD </i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
