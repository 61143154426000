import React from 'react'
import './footer.css'
import { Link, useNavigate } from "react-router-dom";


export default function Footer() {
    const navii = useNavigate();
  return (
      <>
    <footer id="footer">
   
    <div className="footer-top">
        <div className="container">
            <div className="row">
                <div className="col-lg-3 col-md-6 footer-links">
                    <h4>Useful Links</h4>
                    <ul>
                        <li><i className="bx bx-chevron-right"></i><a href='#'>Home</a></li>
                        <li><i className="bx bx-chevron-right"></i> <a href="#">About us</a></li>
                        <li><i className="bx bx-chevron-right"></i> <a href="#">Products</a></li>
                        <li><i className="bx bx-chevron-right"></i> <a href="#">Quality Control</a></li>
                    </ul>
                </div>
                <div className="col-lg-3 col-md-6 footer-links">
                    <h4>Our Major Product</h4>
                    <ul>
                        <li><i className="bx bx-chevron-right"></i> Masking Tape</li>
                        <li><i className="bx bx-chevron-right"></i> BOPP Tape</li>
                        <li><i className="bx bx-chevron-right"></i> Double Sided Tissue Tape</li>
                        <li><i className="bx bx-chevron-right"></i> Rubber Tape</li>
                        <li><i className="bx bx-chevron-right"></i> Packing Tape</li>
                    </ul>
                </div>
                <div className="col-lg-3 col-md-6 footer-contact">
                    <h4>Contact Us</h4>
                    <p> ST 1/14 <br/> ,Sector 15 Korangi Industrial Area<br/> Karachi,Pakistan <br/><br/> <strong>Phone:</strong> 0321-2434398<br/> <strong>Email:</strong> zuhair@kosherpvt.com<br/> </p>
                </div>
                <div className="col-lg-3 col-md-6 footer-info">
                    <h3>About Kosher</h3>
                    <p>Kosher private limited is a manufacturer and distributor of superior quality self adhesive tapes in Pakistan. Kosher private limited offers wide range of self adhesive tapes.</p>
                    <div className="social-links mt-3"> <a href="#" className="twitter"><i className="fa fa-twitter"></i></a> <a href="#" className="facebook"><i className="fa fa-facebook-square"></i></a> <a href="#" className="instagram"><i className="fa fa-instagram"></i></a> <a href="https://wa.me/923212434398" className="linkedin"><i className="fa fa-whatsapp"></i></a> </div>
                </div>
            </div>
        </div>
    </div>
    <div className="container">
        <div className="copyright"> © Copyright <strong><span>CryptoFogg</span></strong>. All Rights Reserved </div>
        <div className="credits"> Designed by <a href="#">Syed Ali Imran Hussain</a> </div>
    </div>
</footer>
  </>
  )
}
