import React from 'react'
import './AdhesiveTapeStyle.css'
import { NavLink,Outlet } from 'react-router-dom'
import Footer from '../../Footer'
import Motivation from '../../../components/MotivationalQuote'


export default function AdhesiveTape() {
  return (
    <>
    <br/><br/><br/>

       <h2 className='text-center'><strong>Adhesive Tape</strong></h2>
         <div className="container-fluid">
      <div className="row">
        <div className="col-md-3">
        
            <ul className="list-group ">
                <li className='list-group-item ProductList'><NavLink to="/adhesivetape/clothtape" className='txt' > Cloth/Duct Tape </NavLink></li>
                <li className='list-group-item ProductList'><NavLink to="/adhesivetape/maskingtape" className='txt'> Masking Tape </NavLink></li>
                <li className='list-group-item ProductList'><NavLink to="/adhesivetape/bopptape" className='txt'> BOPP Tape </NavLink></li>
                <li className='list-group-item ProductList'><NavLink to="/adhesivetape/pvctape" className='txt'> PVC Tape </NavLink></li>
                <li className='list-group-item ProductList'><NavLink to="/adhesivetape/doublesidedtape" className='txt'> Double Sided Tape </NavLink></li>
            </ul>
        
        </div>
        <div className="col-md-8">
        <Outlet/>

        </div>
      </div>
      <br/><br/><br/>
        <Motivation />
      <br/><br/>
      <Footer />
    </div>
    </>
  )
}
