import React,{ useRef }  from "react";
import UserLogo from "../images/user.png";
import "./Contact.css";
import emailjs from 'emailjs-com';
import Footer from "./Footer";

export default function ContactUs() {
  
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_v1lzgu8', 'template_vc0t55d', e.target , 'user_uyP8xLwL42Lkb2MAa9FUm')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
      <div class="alert alert-success" role="alert">
      successfuly submited
    </div>
  };
 
  return (
    <>
    <br/><br/>
      <div className="container" style={{ backgroundColor: "white" }}>
        <h1 className="text-center">Message Us</h1>
        <p className="text-center">
          We would be glad to hear from you. Kindly send us your comments,
          opinions and feedback through Message
        </p>
        <div className="row" style={{ paddingTop: "2.813rem" }}>
          {/* <div className="col-md-1"></div> */}
          <div
            className="col-md-4 mediaQueryforContactDetail"
            style={{ paddingTop: "3%" }}
          >
            <img src={UserLogo} alt="User Logo" />
            <br />
            <span>
              <b>Zuhair Ahmed Butt</b>
            </span>
            <br />
            <i>CEO</i>
            <br />

            <span
              className="fa fa-envelope"
              style={{ color: "red", paddingTop: "2%" }}
            >
              <span style={{ color: "black" }}>
                <b> zuhair@kosherpvt.com / khosherint@yahoo.com</b>
              </span>
            </span>
            <br />
            <span
              className="fa fa-phone"
              style={{ color: "blue", paddingTop: "2%" }}
            >
              <span style={{ color: "black" }}>
                <b> 0321-2434398</b>
              </span>
            </span>
            <br />
            <span
              className="fa fa-map-marker"
              style={{ color: "green", paddingTop: "2%" }}
            >
              <span style={{ color: "black" }}>
                <b>
                  {" "}
                  ST 1/14, Sector 15 Korangi Industrial Area, Karachi, Pakistan
                </b>
              </span>
            </span>
          </div>
          {/* <div className="col-md-1"></div> */}
          <div
            className="col-md-4 mediaQueryforContactDetail"
            style={{ paddingTop: "3%" }}
          >
            <img src={UserLogo} alt="User Logo" />
            <br />
            <span>
              <b>Qayam-ud-Din Khan</b>
            </span>
            <br />
            <i>FOUNDER</i>
            <br />

            <span
              className="fa fa-envelope"
              style={{ color: "red", paddingTop: "2%" }}
            >
              <span style={{ color: "black" }}>
                <b> qayam@kosherpvt.com</b>
              </span>
            </span>
            <br />
            {/* <span
              className="fa fa-phone"
              style={{ color: "blue", paddingTop: "2%" }}
            >
              <span style={{ color: "black" }}>
                <b>-</b>
              </span>
            </span> */}
            <br />
            <span
              className="fa fa-map-marker"
              style={{ color: "green", paddingTop: "2%" }}
            >
              <span style={{ color: "black" }}>
                <b>
                  {" "}
                  ST 1/14, Sector 15 Korangi Industrial Area, Karachi, Pakistan
                </b>
              </span>
            </span>
          </div>
          <div
            className="col-md-4 mediaQueryforContactDetail"
            style={{ paddingTop: "3%" }}
          >
            <img src={UserLogo} alt="User Logo" />
            <br />
            <span>
              <b>Salman Sualeheen</b>
            </span>
            <br />
            <i>DIRECTOR</i>
            <br />

            <span
              className="fa fa-envelope"
              style={{ color: "red", paddingTop: "2%" }}
            >
              <span style={{ color: "black" }}>
                <b> salman@kosherpvt.com </b>
              </span>
            </span>
            <br />
            {/* <span
              className="fa fa-phone"
              style={{ color: "blue", paddingTop: "2%" }}
            >
              <span style={{ color: "black" }}>
                <b>-</b>
              </span>
            </span> */}
            <br />
            <span
              className="fa fa-map-marker"
              style={{ color: "green", paddingTop: "2%" }}
            >
              <span style={{ color: "black" }}>
                <b>
                  {" "}
                  ST 1/14, Sector 15 Korangi Industrial Area, Karachi, Pakistan
                </b>
              </span>
            </span>
          </div>

          {/* <div className="col-md-6">
            <form onSubmit={sendEmail}>
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="fname"
                name="name"
                placeholder="Your name.."
                autoComplete="off"
              />
              <label htmlFor="email">Email</label>
              <input
                type="text"
                id="lname"
                name="email"
                placeholder="Your E-mail"
                autoComplete="off"
              />
              <label htmlFor="subject">Subject</label>
              <input
                type="text"
                id="lname"
                name="subject"
                placeholder="subject"
                autoComplete="off"
              />
              <label htmlFor="message">Message</label>
              <textarea
                id="subject"
                name="message"
                placeholder="Write something.."
                style={{ height: "170px" }}
              ></textarea>
              <button type="submit" value="Send" id="subBtn">
                <i class="fa fa-send-o"> Send</i>
              </button>
            </form>
          </div> */}
        </div>
        <br /><br />
        <div className="row">
            
          <div className="col-md-12">
            <form onSubmit={sendEmail}>
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="fname"
                name="name"
                placeholder="Your name.."
                autoComplete="off"
              />
              <label htmlFor="email">Email</label>
              <input
                type="text"
                id="lname"
                name="email"
                placeholder="Your E-mail"
                autoComplete="off"
              />
              <label htmlFor="subject">Subject</label>
              <input
                type="text"
                id="lname"
                name="subject"
                placeholder="subject"
                autoComplete="off"
              />
              <label htmlFor="message">Message</label>
              <textarea
                id="subject"
                name="message"
                placeholder="Write something.."
                style={{ height: "170px" }}
              ></textarea>
              <button type="submit" className="form-control" value="Send" id="subBtn">
                <i class="fa fa-send-o"> Send</i>
              </button>
            </form>
          </div> 

        </div>
      </div>
      <br/><br/><br/><br/><br/><br/><br/><br/>
        <Footer/>
    </>
  );
}
