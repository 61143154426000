import React from "react";
import "./QualityControStylel.css";
import QualityControll from "../images/quality.jpg";
import Footer from "../Pages/Footer";
import MotivationalQuote from "../components/MotivationalQuote";
import Certification from "./Certification";

export default function QualityControl() {
  const contentStyl = {
    color: "#2b2d6a",
  };
  return (
    <>
      <div className="container-fluid parentDiv">
        <div className="row">
          <img src={QualityControll} alt="" srcset=""  id="qualitycontrolimg"/>
        </div>
      </div>
      <div className="container parentQualityControlContent my-4">
        <h2 style={contentStyl} id='headdd'>
          <strong>Quality Control</strong>
        </h2>
        <hr />
        <p className="contennnt">
          <b>
            We believe that quality is a condition and not a distinguishing
            property. You can, therefore, have every confidence that our tapes
            are of constant quality with excellent adhesion performance. Our
            quality policy also focuses on innovative product development,
            flexible and efficient production and timely delivery. In addition
            to our controlled processes, our focus is on quality awareness and
            controls on a daily basis.
          </b>
        </p>

        <h2 style={contentStyl} id='headdd'>
          <strong>Aim for operational excellence</strong>
        </h2>
        <p className="contennnt">
          <b>
            An important quality objective is supply chain excellence. We follow
            our performance in the logistics chain closely. From the way in
            which we procure raw materials to our delivery reliability. Every
            step influences the degree in which we supply consistent tapes on
            time for prices in line with the market to your site. Our aim is
            "operational excellence" in our order processing, production and
            logistics departments.
          </b>
        </p>

        <h2 style={contentStyl} id='headdd'>
          <strong>Employee quality awareness</strong>
        </h2>
        <p className="contennnt">
          <b>
            Employees who operate machines and ensure processes are fulfilled
            determine the difference between good and not so good quality. We,
            therefore, make them aware of the consequences of their actions and
            point out the importance of good collaboration. We work on
            increasing efficient processes and continuously improving quality
            throughout the entire company by providing knowledge and insights in
            best practice through training.
          </b>
        </p>

        <h2 style={contentStyl} id='headdd'>
          <strong>Daily QC perfomance</strong>
        </h2>
        <p className="contennnt">
          <b>
            We produce our tapes in accordance with technical specifications.
            Depending on the tape type and application, we perform tests to
            guarantee that the tape meets specific industrial and/or customer
            requirements. Our quality and production departments check quality
            with regard to the following standard and/or specific \ properties
            (this is not an exhaustive list)
            <ul>
              <li>Adhesive strength</li>
              <li>Temperature performance</li>
              <li>Delivery speed</li>
              <li>Tensile strength</li>
            </ul>
          </b>
        </p>

        <div className="qualityPolici my-4">
          <h2 style={contentStyl} id='headdd'>
            <strong>Quality Policies</strong>
          </h2>
          <hr />
          <p className="contennnt">
            <strong>
              In order to compete effectively in the international market and to
              assure the customer entire satisfaction, we set a quality policy.
              "We are committed to produce quality yarn & gray fabric as
              specified by the customer and will improve our quality management
              system continuously". To compete in the highly competitive sector
              of textile, all the units of Hussain Group are (ISO 9001) (ISO
              14001) Certified. The exemplary labour management teaming and
              state-of-the-art quality control applied assures product of high
              quality reputation The lines on which the corporate culture is
              based are blend of professionalism, cost effectiveness and quality
              consciousness. Protection of employee's health, plant and
              machinery are above the set standards aimed at environmental
              preservation.
            </strong>
          </p>
        </div>
      </div><br/>
      <Certification />
      <br />
      <MotivationalQuote />

      <Footer />
    </>
  );
}
